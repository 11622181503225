import { renderRichText } from "gatsby-source-contentful/rich-text";
import * as React from "react";
import * as styles from "./homepage-project.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useViewportPosition } from "../../hooks/use-viewport-position";

const HomepageProject = props => {
    const { project } = props;

    const paraContent = renderRichText(project.homepageParagraph);

    return (
        <Link to={`/project/${project.slug}`} className={styles.project}>
            <div className={styles.content}>
                <h2>{project.title}</h2>
                <h5>{project.client}</h5>
                {paraContent}
            </div>
            <div className={styles.imageContainer}>
                <GatsbyImage image={getImage(project.homepageImage)} />
            </div>
        </Link>
    );
};

export default HomepageProject;
