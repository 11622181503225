import { graphql } from "gatsby";
import * as React from "react";
import HomepageProject from "../components/homepage-project/homepage-project";
import Layout from "../components/layout/layout";
import * as styles from "./index.module.scss";
import myFace from "../images/me.jpeg";

const metaDescription = `Dasha Morris - Product Designer`;

const Index = ({ data }) => {
    return (
        <Layout
            pageTitle="Dasha Morris - Product Designer"
            pageMeta={metaDescription}
        >
            <section className={styles.heroSection}>
                <img src={myFace} />
                <div className={styles.heroCopy}>
                    <h1>Dasha Morris</h1>
                    <h4>Senior Product Designer</h4>
                    <h3 className={styles.heroPara}>
                        I create elegant solutions to solve complex product
                        design challenges, from the smallest details to the
                        big-picture idea.
                    </h3>
                </div>
            </section>
            <div>
                {data.allContentfulProject.nodes.map((projectData, index) => (
                    <HomepageProject
                        key={projectData.id}
                        project={projectData}
                    />
                ))}
            </div>
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query {
        allContentfulProject(
            filter: { secret: { eq: false } }
            sort: { fields: order }
        ) {
            nodes {
                id
                title
                client
                slug
                homepageParagraph {
                    raw
                }
                homepageImage {
                    gatsbyImageData(
                        height: 420
                        placeholder: NONE
                        formats: [AUTO]
                    )
                }
            }
        }
    }
`;
